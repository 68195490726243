@base: #252525;
@smartphones: ~"only screen and (max-width: 780px)";

body { background:white; margin: 0; font-family: 'Lato', sans-serif; background-color:#F1F2F1}
* {box-sizing: border-box;}

h1, h2, h3, h4 {
	font-family: 'Lato';
}

.about-me {
	font-family: 'Slabo 27px', serif;
	position:fixed;
	right: 0px;
	width: 300px;
	height: 100%;
	top:0px;
	background-color: @base;
	color:white;
	overflow-y: auto;

	padding: 20px;
	@media @smartphones {
		position:static;
		width: 100%;
	}
}

.profile-image {
	text-align: center;

	img{
		display:inline-block;
		border-radius: 50%;
		overflow:hidden;
		vertical-align: top;
		border:2px white solid;
	}
}
.content {
	width: calc(~"100% - 300px");
	@media @smartphones {
		width: 100%;
	}
}
.list-view{
	padding-top: 20px;
	display:flex;
	justify-content: space-around;
	
	flex-wrap: wrap;

}
.project-item { 
	max-width: 270px;
	box-shadow: 0 0 8px rgba(0,0,0,.3);
	position:relative;
	margin-bottom: 40px;

	display:block;
	cursor:pointer;

	img{
		width:100%;
		vertical-align: top;
	}

	.overlay{
		position:absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0px;
		top:0px;
		bottom:0px;
		right:0px;
		background:rgba(0,0,0,.5);
		opacity: 0;
		color:white;
		transition: opacity .4s;
	}
	.title { 
		margin-top: -20px;
		transition: margin-top .6s;
		font-size: 30px;
		text-align: center;
	}

	&:hover{
		.overlay{
			opacity: 1;
		}
		.title{
			margin-top:0;
		}
	}
}

.project-content{
	.back {
		position:fixed;
		left: 40px;
		top: 40px;
		padding: 10px;
		background:white;
		opacity: .5;
		transition: opacity .5s;
		cursor: pointer;
		z-index: 11;
		&:hover{
			opacity: 1;
		}

		@media @smartphones {
			position: static;
			opacity: 1;
		}
	}

	.images { 
		max-width: 1200px;
		padding: 20px;
		margin:auto;
		background-color: white;
	}
	.info-box {
		position:fixed;
		left: 40px;
		bottom: 20px;
		width: 400px;
		padding: 30px;
		background:white;
		box-shadow: 0px 0px 16px rgba(0,0,0, .3);
		z-index: 10;

		@media @smartphones {
			position:relative;
			width: 100%;
			margin-bottom: 10px;
			bottom: 0;
			left: 0;
		}

		h2 { margin-top: 0px;}
		.link {
			margin-top: 20px;
		}
		/*.description, .link{
			transition: height .5s;
			overflow:hidden;
		}*/
		.minimize {
			position:absolute;
			right: 20px;
			top:20px;
		}

		&.minimized{
			padding: 15px 15px 15px 30px;
			opacity: .3;
			.description, .link{
				display:none;
			}
			h2 {
				margin-bottom: 0;
			}
		}

	}
	
}
.image {
	text-align: center;
	img{
		width:100%;
		&.portrait{
			max-width: 50%;
		}
	}
}

.embedvideo {
	position: relative;
	padding-bottom: 54.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.embedvideo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border:0;
}

.fadeinmove {
	animation: fadeinmove .5s;
	animation-fill-mode: backwards;
}
.fadein {
	animation: fadein 1s;
	animation-fill-mode: backwards;
}
.animdelay { 
	animation-delay: .5s;
}
@keyframes fadeinmove {
    from {opacity: 0; transform: translateY(-50px)}
    to {opacity: 1; transform: translateY(0px)}
}

@keyframes fadein {
    from {opacity: 0; }
    to {opacity: 1; }
}
